import React from 'react';
import { Flex, Hide, Image, NakedButton, Text } from '@qga/roo-ui/components';
import { NUMBER, TEXT } from '@/components/ContactUs';
import Link from '@/components/Link';
import RequestCallbackModal from '@/components/RequestCallbackModal';
import ToggleContent from '@experiences-ui/shared/v2/components/ToggleContent';
import withProps from '@/components/withProps';
import getFeatureFlag from '@/utils/getFeatureFlag';
import NavBarItem from '../NavBarItem';
import iconCallback from './assets/icon-call-back.svg';
import iconCall from './assets/icon-call.svg';

const ActionText = withProps({
  color: 'greys.charcoal',
  letterSpacing: '1px',
  lineHeight: 'initial',
  fontWeight: 'bold',
})(Text);

const ContactUs = () => (
  <ToggleContent
    toggle={(open) => {
      const renderCallback = () => {
        if (!getFeatureFlag('REQUEST_CALLBACK')) {
          return null;
        }

        return (
          <NavBarItem>
            <NakedButton onClick={open}>
              <Flex alignItems="center">
                <Image src={iconCallback} alt="Call me" />
                <Hide xs sm>
                  <ActionText ml="2">Call me</ActionText>
                </Hide>
              </Flex>
            </NakedButton>
          </NavBarItem>
        );
      };

      const renderPhoneNumber = () => (
        <NavBarItem>
          <Link.Text data-testid="PHONE_NUMBER" href={`tel:${NUMBER}`}>
            <Flex alignItems="center">
              <Image src={iconCall} alt={TEXT} />
              <Hide xs sm>
                <ActionText ml="2">{TEXT}</ActionText>
              </Hide>
            </Flex>
          </Link.Text>
        </NavBarItem>
      );

      return (
        <Flex>
          {renderCallback()}
          {renderPhoneNumber()}
        </Flex>
      );
    }}
    content={(close) => <RequestCallbackModal onClose={close} />}
  />
);

export default ContactUs;
