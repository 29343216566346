import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useWebChat } from '@/components/WebChat';
import WebChatSpecialHours from '@/propTypes/WebChatSpecialHours';

export const AppSettingsContext = React.createContext({
  pageHeader: [],
});

const AppSettingsProvider = ({ webChatSpecialHours, children, ...value }) => {
  const webChat = useWebChat();

  useEffect(() => {
    if (!webChat) {
      return;
    }

    webChat.setSpecialHours(webChatSpecialHours);
  }, [webChatSpecialHours, webChat]);

  return (
    <AppSettingsContext.Provider value={value}>
      {children}
    </AppSettingsContext.Provider>
  );
};

AppSettingsProvider.propTypes = {
  webChatSpecialHours: WebChatSpecialHours.isRequired,
  children: PropTypes.node.isRequired,
};

export default AppSettingsProvider;
