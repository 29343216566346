import React from 'react';
import { Flex } from '@qga/roo-ui/components';
import Logo from '@/v2/components/ui/Logo';
import NavBar from './components/NavBar';

const Navigation = () => (
  <NavBar
    links={[
      {
        key: 'LOGO',
        href: '/',
        children: (
          <Flex>
            <Logo />
          </Flex>
        ),
      },
    ]}
  />
);

export default Navigation;
