import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Flex, Button, Text, Box } from '@qga/roo-ui/components';
import Modal from '@/components/Modal';
import Link from '@/components/Link';
import { useAuth } from '@/components/Auth';

const StyledBox = styled(Box)`
  width: 120px;
  height: 1px;
  margin: 11px 30px;
  background: rgba(0, 0, 0, 0.0001);
  box-shadow: inset 0px -0.5px 0px #dddddd;
`;

const LoginSelection = ({ onClose }) => {
  const { login } = useAuth();
  useEffect(() => {
    document.querySelector('#portal-root').innerHTML = '';
  }, []);

  return (
    <Modal
      appElement={
        document.querySelector('#__next') || document.querySelector('#root')
      }
      onClose={onClose}
      width="440px"
    >
      <Modal.Header>Login</Modal.Header>
      <Modal.Body>
        <Flex
          flexDirection="column"
          alignItems="center"
          my={['120px', null, '0px']}
        >
          <Text fontSize="md" textAlign="center">
            Login to your Qantas Frequent Flyer account
          </Text>
          <Box mt={6}>
            <Button
              variant="primary"
              onClick={() => {
                login();
                onClose();
              }}
              data-testid="LOGIN_BUTTON"
            >
              LOGIN TO QANTAS FREQUENT FLYER
            </Button>
          </Box>
          <Flex mt={6}>
            <StyledBox />
            <Text fontSize="sm">OR</Text>
            <StyledBox />
          </Flex>
          <Box mt={6}>
            <Text fontSize="md" textAlign="center">
              View your Qantas Tours booking
            </Text>
          </Box>
          <Box mt={3} width="250px" textAlign="center">
            <Text fontSize="sm" color="greys.steel" textAlign="center">
              View Qantas Tours curated by Qantas, operated by TripADeal.
            </Text>
          </Box>
          <Box my={6}>
            <Link.Button
              href="https://tours.qantas.com/signin"
              variant="primary"
              outline
              data-testid="TAD_LINK"
            >
              VIEW YOUR QANTAS TOURS
            </Link.Button>
          </Box>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default LoginSelection;
