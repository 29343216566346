import accounting from 'accounting';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Icon, NakedButton } from '@qga/roo-ui/components';
import NavBarItem from '../../../../../NavBarItem';
import LoginSelection from '@/components/LoginSelection';
import ToggleContent from '@experiences-ui/shared/v2/components/ToggleContent';
import { useAuth } from '@/components/Auth';

const Authentication = React.memo(({ onClose }) => {
  const { memberDetails, logout } = useAuth();

  return !memberDetails ? (
    <Box ml="3">
      <ToggleContent
        toggle={(show) => (
          <NakedButton onClick={() => show()}>
            <NavBarItem>
              <Icon name="person" color="ui.link" />
              <Box ml="4" color="ui.link" fontWeight="bold">
                LOG IN
              </Box>
            </NavBarItem>
          </NakedButton>
        )}
        content={(close) => (
          <LoginSelection
            onClose={() => {
              close();
              onClose();
            }}
          />
        )}
      />
    </Box>
  ) : (
    <Flex alignItems="center" ml="3" className="data-hj-suppress">
      <Icon name="person" color="ui.link" />
      <Box fontSize="xs" ml="4">
        <Box fontWeight="bold">
          {`${memberDetails.title} ${memberDetails.firstName} ${memberDetails.lastName} (${memberDetails.memberId})`}
        </Box>
        <Box>
          {`${accounting.formatNumber(memberDetails.points)} points • ${
            memberDetails.membershipTier
          }`}
          &nbsp; (
          <NakedButton
            color="ui.link"
            hoverColor="ui.linkHover"
            textDecoration="underline"
            onClick={logout}
          >
            Log out
          </NakedButton>
          )
        </Box>
      </Box>
    </Flex>
  );
});

Authentication.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Authentication;
