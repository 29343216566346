/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import onA11yKeyDown from '@/utils/onA11yKeyDown';

const preventInsideClickEventPropagatingToWindow = (event) =>
  event.stopPropagation();

const ClickOutside = ({ children, onOutsideClick }) => {
  useEffect(() => {
    const buttonA11yHandler = onA11yKeyDown('button', onOutsideClick);

    window.addEventListener('click', onOutsideClick);
    window.addEventListener('keydown', buttonA11yHandler);

    return () => {
      window.removeEventListener('click', onOutsideClick);
      window.removeEventListener('keydown', buttonA11yHandler);
    };
  }, [onOutsideClick]);

  return (
    <div
      onClick={preventInsideClickEventPropagatingToWindow}
      onKeyDown={onA11yKeyDown('button', (event) => event.stopPropagation())}
    >
      {children}
    </div>
  );
};

ClickOutside.propTypes = {
  children: PropTypes.node.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
};

export default ClickOutside;
