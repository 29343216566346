import PropTypes from 'prop-types';
import React from 'react';
import { flatten } from 'ramda';
import { NakedButton } from '@qga/roo-ui/components';
import Link from '@/components/Link';
import Item from '../Item';
import dataLayer from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

const getExpandIcon = (expanded) => (expanded ? 'expandLess' : 'expandMore');

const Pages = React.memo(({ children, data, expanded, onClick }) => {
  const shouldUseNewEvents = useGA4Events();

  const isParentExpanded = expanded.startsWith(children);

  const onLinkClick = (text, url, index) => {
    if (shouldUseNewEvents) {
      dataLayer.menuEvent({ text, url, index });
    }
  };
  return (
    <>
      <NakedButton
        data-testid="PARENT_BUTTON"
        onClick={() => onClick(children)}
      >
        <Item index={1} icon={getExpandIcon(isParentExpanded)}>
          {children}
        </Item>
      </NakedButton>
      {isParentExpanded &&
        flatten(data).map(({ links }) =>
          links.map(({ text, url, key }, index) => (
            <Link.Naked
              data-testid="CHILD_LINK"
              href={url}
              key={key}
              onClick={() => onLinkClick(text, url, index)}
            >
              <Item index={2}>{text}</Item>
            </Link.Naked>
          )),
        )}
    </>
  );
});

Pages.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  expanded: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Pages;
