import PropTypes from 'prop-types';

export default {
  memberId: PropTypes.string,
  title: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  membershipTier: PropTypes.string,
};
