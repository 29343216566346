import PropTypes from 'prop-types';
import React, { useRef, useState, useCallback } from 'react';
import { Box } from '@qga/roo-ui/components';
import Portal from '../../v2/components/Portal';
import ToggleContent from '../../v2/components/ToggleContent';
import throttle from '../../v2/utils/throttle';
import useBreakpoints, { isScreen } from '../../v2/hooks/useBreakpoints';

const POSITION = {
  BOTTOM: 'BOTTOM',
  BOTTOM_WITH_OFFSET: 'BOTTOM_WITH_OFFSET',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
};

const getPositionProps = (
  position,
  offsetPosition,
  menuDimensions,
  smallScreen,
) => {
  const screenWidth = document.body.scrollWidth;
  const positions = { top: offsetPosition.bottom };

  if (smallScreen) {
    return {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
  }

  if (position === POSITION.BOTTOM_WITH_OFFSET) {
    return {
      ...positions,
      left: offsetPosition.left,
      right: offsetPosition.right,
    };
  }

  if (position === POSITION.BOTTOM) {
    return {
      ...positions,
      left: 0,
      right: 0,
    };
  }

  if (position === POSITION.BOTTOM_LEFT) {
    return {
      ...positions,
      left: offsetPosition.left,
    };
  }

  if (position === POSITION.BOTTOM_RIGHT) {
    return screenWidth - offsetPosition.right <= menuDimensions.width
      ? { ...positions, left: 0 }
      : {
          ...positions,
          right: offsetPosition.right,
        };
  }

  return positions;
};

const Dropdown = ({
  toggle,
  content,
  position,
  fullscreenOnMobile,
  disabled,
}) => {
  const ref = useRef(null);
  const [offsetPosition, setOffsetPosition] = useState({});
  const [menuDimensions, setMenuDimensions] = useState({});
  const handleResizeRef = useRef();
  const positionEffect = useCallback((element) => {
    if (!element) {
      window.removeEventListener('resize', handleResizeRef.current);

      return;
    }

    handleResizeRef.current = throttle(() => {
      if (!ref.current) {
        return;
      }

      const boundingClientRect = ref.current.getBoundingClientRect();
      const eleBoundingClientRect = element.getBoundingClientRect();

      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const screenWidth = document.body.scrollWidth;

      setMenuDimensions({
        height: eleBoundingClientRect.height,
        width: eleBoundingClientRect.width,
      });

      setOffsetPosition({
        top: scrollTop + boundingClientRect.top,
        bottom: scrollTop + boundingClientRect.bottom,
        left: scrollLeft + boundingClientRect.left,
        right: screenWidth - boundingClientRect.right,
        height: boundingClientRect.height,
        width: boundingClientRect.width,
      });
    });

    handleResizeRef.current();
    window.addEventListener('resize', handleResizeRef.current);
  }, []);

  const breakpoints = useBreakpoints();
  const smallScreen = isScreen(breakpoints)('xs', 'sm') && fullscreenOnMobile;

  return (
    <ToggleContent
      toggle={(open, ...args) =>
        disabled ? toggle(() => {}, ref, ...args) : toggle(open, ref, ...args)
      }
      content={(...args) => (
        <Portal selector="portal-root">
          <Box
            data-testid="DROPDOWN_ITEM"
            position={smallScreen ? 'fixed' : 'absolute'}
            height={smallScreen ? '100vh' : 'inherit'}
            zIndex="popup"
            ref={positionEffect}
            {...getPositionProps(
              position,
              offsetPosition,
              menuDimensions,
              smallScreen,
            )}
          >
            {content(...args)}
          </Box>
        </Portal>
      )}
    />
  );
};

Dropdown.defaultProps = {
  position: POSITION.BOTTOM_LEFT,
  fullscreenOnMobile: false,
};

Dropdown.propTypes = {
  ...ToggleContent.propTypes,
  position: PropTypes.oneOf(Object.values(POSITION)),
  fullscreenOnMobile: PropTypes.bool,
};

Dropdown.position = POSITION;

export default Dropdown;
