import React from 'react';
import PropTypes from 'prop-types';
import { Icon, NakedButton, Flex } from '@qga/roo-ui/components';
import NavBarItem from '../../../NavBarItem';

const Toggler = React.memo(({ onClick, icon }) => (
  <NakedButton data-testid="TOGGLER" onClick={onClick}>
    <NavBarItem>
      <Flex
        width="50px"
        justifyContent="center"
        alignItems="center"
        borderLeft="1"
        borderColor="greys.alto"
      >
        <Icon name={icon} />
      </Flex>
    </NavBarItem>
  </NakedButton>
));

Toggler.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

export default Toggler;
