import React from 'react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { Image } from '@qga/roo-ui/components';
import withProps from '@/v2/../components/withProps';
import logoDark from './assets/logo.svg';
import logoLight from './assets/logo-light.svg';

interface LogoProps {
  variant?: LogoVariants;
}

const Logo = styled(Image)`
  display: inline-flex;
  height: ${rem('30px')};
`;

type LogoVariants = 'light' | 'dark';

export default withProps(({ variant }: LogoProps) => ({
  src: variant === 'light' ? logoLight : logoDark,
  alt: 'Qantas Holidays',
}))(Logo) as React.FC<LogoProps>;
