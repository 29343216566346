import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex } from '@qga/roo-ui/components';
import SimplicityHeader from '@/components/SimplicityHeader';
import Toggler from '../Toggler';
import Authentication from './components/Authentication';
import Navigation from './components/Navigation';

const Wrapper = styled(Flex)`
  ${(props) =>
    !props.visible &&
    css`
      transform: translateX(-100%);
      opacity: 0;
    `}
`;

const Drawer = ({ visible, onClose }) => (
  <>
    {visible && <Global styles={{ body: { overflow: 'hidden' } }} />}
    <Wrapper
      flexDirection="column"
      position="fixed"
      zIndex="popup"
      top="0"
      bottom="0"
      right="0"
      left="0"
      bg="rgba(0, 0, 0, 0.7)"
      visible={visible}
      data-testid="DRAWER_WRAPPER"
    >
      <Flex bg="white">
        <Authentication onClose={onClose} />
        <Box ml="auto">
          <Toggler onClick={onClose} icon="close" />
        </Box>
      </Flex>
      <Flex flex="1" overflow="hidden">
        <Box bg="greys.charcoal" flex="1" overflow="auto">
          {visible && <Navigation />}
          <SimplicityHeader mobile />
        </Box>
        <Box width="50px" />
      </Flex>
    </Wrapper>
  </>
);

Drawer.defaultProps = {
  visible: false,
};

Drawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default Drawer;
