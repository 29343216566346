import PropTypes from 'prop-types';

const specialHours = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

const WebChatSpecialHours = PropTypes.arrayOf(PropTypes.shape(specialHours));

export default WebChatSpecialHours;
