const SPACE = ' ';
const ENTER = 'Enter';

const onA11yKeyDown = (type, handler) => (event) => {
  const keys = {
    button: [SPACE, ENTER],
    checkbox: [SPACE],
    link: [ENTER],
    select: [SPACE],
  }[type];

  if (!keys.includes(event.key)) {
    return;
  }

  handler(event);
};

export default onA11yKeyDown;
