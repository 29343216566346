import { Flex } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import withProps from '@/components/withProps';

const NavBarItem = withProps({
  px: ['3', null, '4'],
  alignItems: 'center',
  height: ['54px', null, '64px'],
})(styled(Flex)`
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;

  ${(props) =>
    props.active &&
    css`
      border-bottom-color: ${themeGet('colors.ui.link')(props)};
      font-weight: bold;
    `}

  &:last-of-type {
    margin-right: 0;
  }
  &:first-of-type {
    margin-left: 0;
  }
`);

export default NavBarItem;
