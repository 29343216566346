/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { Link as StyledLink } from '@qga/roo-ui/components';
import { color, fontWeight, space } from 'styled-system';
import withProps from '@/components/withProps';
import Button from '@/components/Button';
import withLink from './withLink';

const ButtonLink = withProps({
  as: 'a',
})(Button);

const NakedLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: inherit;

  ${color}
  ${fontWeight} 
  ${space}
`;

const CoverLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

/**
 * @deprecated Use Link.tsx
 *
 * /apps/qantas-holidays/src/v2/components/ui/Link/Link.tsx
 *
 * import Link from '@/v2/components/ui/Link';
 *
 * Link.Text now accepts a variant of 'light' | 'dark'
 */
const Link = {};

Link.Text = withLink(StyledLink);
Link.Button = withLink(ButtonLink);
Link.Naked = withLink(NakedLink);
Link.Cover = withLink(CoverLink);

export default Link;
