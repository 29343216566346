import styled from '@emotion/styled';
import accounting from 'accounting';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Icon, Image, Text } from '@qga/roo-ui/components';
import AuthenticationDetails from '@/propTypes/AuthenticationDetails';
import withProps from '@/components/withProps';
import Button from '@/components/Button';
import decal from './assets/decal.svg';

const PersonIcon = withProps({
  name: 'person',
  p: 1,
  size: '30px',
})(styled(Icon)`
  border: 1px solid currentColor;
  border-radius: 100%;
`);

const StyledBox = styled(Box)`
  background: linear-gradient(90deg, #d20000 0, #f70500);
`;

const MemberMenu = ({
  memberId,
  firstName,
  lastName,
  points,
  membershipTier,
  onLogout,
}) => (
  <Box
    color="white"
    bg="brand.primary"
    data-testid="MEMBER_MENU"
    className="data-hj-suppress"
  >
    <StyledBox p="5" textAlign="center" position="relative">
      <Box position="absolute" top="0" right="0">
        <Image src={decal} alt="Qantas Login Diamond Decal" />
      </Box>
      <Box position="relative">
        <PersonIcon mb="2" />
        <br />
        <Text fontWeight="bold">{`${firstName} ${lastName} (${memberId})`}</Text>
        <br />
        <Text>{`${accounting.formatNumber(points)} points`}</Text>
        <br />
        <Text>{membershipTier}</Text>
      </Box>
    </StyledBox>
    <Box
      py="7"
      px="5"
      bg="white"
      border="1"
      borderTop="0"
      borderColor="greys.alto"
    >
      <Button
        data-testid="LOGOUT"
        block
        outline
        variant="primary"
        onClick={onLogout}
      >
        LOGOUT
      </Button>
    </Box>
  </Box>
);

MemberMenu.propTypes = {
  ...AuthenticationDetails,
  onLogout: PropTypes.func.isRequired,
};

export default MemberMenu;
