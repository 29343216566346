import { Icon } from '@qga/roo-ui/components';
import Button from '@/components/Button/Button';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

const GoToTop = () => {
  const handleClick = () => {
    const doc =
      document.documentElement /* Chrome, Firefox, IE, Opera */ ||
      document.body; /* Safari */
    doc.scrollTop = 0;
  };

  const StyledButton = styled(Button)`
    background: ${themeGet('colors.white')};
    color: ${themeGet('colors.greys.charcoal')};
    border-radius: 0;
    width: 48px;
    height: 48px;
    padding: 0;

    &:focus,
    &:hover {
      color: ${themeGet('colors.brand.primary')};
      background: ${themeGet('colors.white')};
    }
  `;

  return (
    <StyledButton
      variant="primary"
      type="button"
      onClick={handleClick}
      title="Go to top of page"
    >
      <Icon name="expandLess" />
    </StyledButton>
  );
};

export default GoToTop;
