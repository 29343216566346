import React from 'react';
import { Flex } from '@qga/roo-ui/components';
import { useAppSettings } from '@/components/AppSettingsProvider';
import Link from '@/components/Link';
import Item from './components/Item';
import Pages from './components/Pages';
import useExpanded from './hooks/useExpanded';
import { useNavigation } from '@/v2/contexts/navigation';
import dataLayer from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

const Navigation = React.memo(() => {
  const { expanded, toggleExpanded } = useExpanded();
  const shouldUseNewEvents = useGA4Events();

  // need to determine if legacy/v2 context
  const app = useAppSettings();
  const nav = useNavigation();
  const pageHeader = app.pageHeader.length > 0 ? app.pageHeader : nav;

  const onClick = (text, url, index) => {
    if (shouldUseNewEvents) {
      dataLayer.menuEvent({ text, url, index });
    }
  };

  return (
    <Flex flexDirection="column">
      {pageHeader.map(({ text, columns, url, key: k }, index) =>
        url ? (
          <Link.Naked
            key={k}
            href={url}
            onClick={() => onClick(text, url, index + 1)}
          >
            <Item index={1}>{text}</Item>
          </Link.Naked>
        ) : (
          <Pages
            key={k}
            expanded={expanded}
            onClick={toggleExpanded}
            data={columns}
          >
            {text}
          </Pages>
        ),
      )}
    </Flex>
  );
});

export default Navigation;
