import styled from '@emotion/styled';
import { themeGet, space } from 'styled-system';

const Divider = styled.hr`
  border-top: 0;
  border-color: ${themeGet('colors.greys.alto')};
  margin: 0;
  ${space}
`;

export default Divider;
