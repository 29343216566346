import { useState, useCallback } from 'react';

const useExpanded = () => {
  const [expanded, setExpanded] = useState('');
  const toggleExpanded = useCallback(
    (target) =>
      setExpanded((prevExpanded) => {
        if (target !== prevExpanded) {
          return target;
        }

        const newTarget = target.split('.').slice(0, -1).join('.');

        return newTarget;
      }),
    [],
  );

  return {
    expanded,
    toggleExpanded,
  };
};

export default useExpanded;
