import accounting from 'accounting';
import React from 'react';
import { Box, Flex, Hide, Icon, Text } from '@qga/roo-ui/components';
import AuthenticationDetails from '@/propTypes/AuthenticationDetails';

const MemberDetails = ({
  title,
  firstName,
  lastName,
  points,
  membershipTier,
}) => (
  <>
    <Hide xs sm>
      <Flex className="data-hj-suppress" alignItems="center">
        <Icon name="person" />
        <Box textAlign="left" mx="4">
          <Text
            fontSize="sm"
            lineHeight="1.35"
            letterSpacing="0"
            fontWeight="bold"
          >
            {`${title} ${firstName} ${lastName}`}
          </Text>
          <br />
          <Text
            fontSize="sm"
            lineHeight="1.35"
            letterSpacing="0"
            fontWeight="bold"
          >
            {`${accounting.formatNumber(points)} Points - ${membershipTier}`}
          </Text>
        </Box>
        <Icon name="expandMore" />
      </Flex>
    </Hide>
    <Hide md lg>
      <Icon name="person" />
    </Hide>
  </>
);

MemberDetails.propTypes = AuthenticationDetails;

export default MemberDetails;
