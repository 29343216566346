import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '@qga/roo-ui/components';
import Link from '@/components/Link';
import NavBarItem from '../../../NavBarItem';

const NavBar = ({ links }) => (
  <Flex alignItems="center">
    {links.map(({ key, children, href, external, active }) => (
      <NavBarItem key={key} active={active}>
        <Link.Text color="greys.charcoal" href={href} external={external}>
          {children}
        </Link.Text>
      </NavBarItem>
    ))}
  </Flex>
);

NavBar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      href: PropTypes.string,
      children: PropTypes.node,
    }),
  ).isRequired,
};

export default NavBar;
