import { Flex, Heading, Text, Icon } from '@qga/roo-ui/components';
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { useSessionStorage } from 'react-use';
import {
  SessionStorage,
  SessionStorageDefaultValue,
} from '@experiences-ui/shared/v2/constants/storage';

export interface BuildInfoProps {
  info?: {
    ENVIRONMENT?: string;
    BUILD_TIME?: number;
    BUILDKITE_BRANCH?: string;
    BUILDKITE_BUILD_NUMBER?: string;
    BUILDKITE_BUILD_URL?: string;
    BUILDKITE_BUILD_AUTHOR?: string;
    BUILDKITE_COMMIT?: string;
  };
  brand: 'jetstar' | 'qantas';
}

const ENV_TO_SHOW = ['sit', 'dev', 'staging'];

const StyledLink = styled.a`
  color: white;
  font-size: 16px;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  &:hover {
    transition: 0.1s;
    color: red;
  }
`;

const Wrapper = styled(Flex)`
  color: white;
  background: black;
  padding: 12px;
  font-size: 16px;
  background: #1b1b1b;

  &:focus,
  &:hover {
    transition: 0.1s;
    background: black;
  }
`;
export const ECS_URL =
  'https://ap-southeast-2.console.aws.amazon.com/ecs/v2/clusters/hooroo-nonprod/services';
export const GITHUB_URL = 'https://github.com/hooroo/experiences-ui/commit';

const BuildInfo = ({ info, brand }: BuildInfoProps) => {
  const [hideBuildInfo, setHideBuildInfo] = useSessionStorage(
    SessionStorage.HIDE_BUILD_INFO,
    SessionStorageDefaultValue.HIDE_BUILD_INFO,
  );

  if (
    hideBuildInfo ||
    // This ensures that both the BUILDKITE vars exist, and that it doesn't render in local dev
    !info?.BUILDKITE_BRANCH ||
    !ENV_TO_SHOW.includes(info?.ENVIRONMENT || '')
  ) {
    return null;
  }

  const {
    BUILD_TIME,
    BUILDKITE_BRANCH,
    BUILDKITE_BUILD_NUMBER,
    BUILDKITE_BUILD_URL,
    BUILDKITE_BUILD_AUTHOR,
    BUILDKITE_COMMIT,
    ENVIRONMENT,
  } = info;

  const brandName = brand === 'qantas' ? 'qp-customer' : 'jetstar-holidays';

  const handleHide = () => {
    setHideBuildInfo(true);
  };

  return (
    <Wrapper mt={5} justifyContent="space-between">
      <Flex flexDirection="column" gap="10px">
        <Flex alignItems="baseline" fontSize="16px">
          <Heading.h4 fontSize="16px" mb={0} mr={3}>
            ENV1 / Branch:
          </Heading.h4>
          <Text fontSize="16px" data-testid="BUILDINFO_ENV" mr={1}>
            {ENVIRONMENT} /{' '}
          </Text>
          <StyledLink
            target="_blank"
            href={`${GITHUB_URL}/${BUILDKITE_COMMIT}`}
            data-testid="BUILDINFO_BRANCH_LINK"
          >
            {BUILDKITE_BRANCH}
          </StyledLink>
        </Flex>
        <Flex alignItems="baseline">
          <Heading.h4 fontSize="16px" mb={0} mr={3}>
            Author:
          </Heading.h4>{' '}
          <Text fontSize="16px" data-testid="BUILDINFO_AUTHOR">
            {BUILDKITE_BUILD_AUTHOR}
          </Text>
        </Flex>
        <Flex alignItems="baseline">
          <Heading.h4 fontSize="16px" mb={0} mr={3}>
            Build:
          </Heading.h4>{' '}
          <StyledLink
            data-testid="BUILDINFO_BUILD_LINK"
            target="_blank"
            href={BUILDKITE_BUILD_URL}
          >
            {BUILDKITE_BUILD_NUMBER}
          </StyledLink>{' '}
          <Text mx={2}>-</Text>
          <Text fontSize="16px" data-testid="BUILDINFO_BUILD_TIME">
            {BUILD_TIME && format(new Date(BUILD_TIME), 'PPpp')}
          </Text>
          <Text mx={2}>/</Text>
          <StyledLink
            data-testid="BUILDINFO_ECS_LINK"
            target="_blank"
            href={`${ECS_URL}/${ENVIRONMENT}-${brandName}-ui-app`}
          >
            ECS
          </StyledLink>
        </Flex>
      </Flex>
      <StyledIcon
        data-testid="BUILDINFO_HIDE"
        name="close"
        onClick={handleHide}
      />
    </Wrapper>
  );
};

export default BuildInfo;
