import { useState, useEffect } from 'react';
import throttle from '../../utils/throttle';
import { BREAK_POINTS_PX } from '../../constants';

const useBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState<
    | {
        xxs?: boolean;
        xs?: boolean;
        sm?: boolean;
        md?: boolean;
        lg?: boolean;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const maps: { [key: string]: Array<number | null> } = {
      xxs: [null, BREAK_POINTS_PX[0]],
      xs: [BREAK_POINTS_PX[0], BREAK_POINTS_PX[1]],
      sm: [BREAK_POINTS_PX[1], BREAK_POINTS_PX[2]],
      md: [BREAK_POINTS_PX[2], BREAK_POINTS_PX[3]],
      lg: [BREAK_POINTS_PX[3], null],
    };

    const validateBreakpoints = () => {
      const width = window.innerWidth;

      const result = Object.keys(maps).reduce((prevValue, key) => {
        const value = maps[key];
        const [min, max] = value;

        const isMin = min ? width >= min : true;
        const isMax = max ? width < max : true;

        return {
          ...prevValue,
          [key]: isMin && isMax,
        };
      }, {});

      setBreakpoints(result);
    };

    const throttledValidateBreakpoints = throttle(validateBreakpoints);

    throttledValidateBreakpoints();

    window.addEventListener('resize', throttledValidateBreakpoints);

    return () => {
      window.removeEventListener('resize', throttledValidateBreakpoints);
    };
  }, []);

  return breakpoints;
};

export default useBreakpoints;
