import PropTypes from 'prop-types';
import React from 'react';
import Link from '@/components/Link';

export const NUMBER = '137066';
export const TEXT = '13 70 66';
export const OVERSEA_NUMBER = '+61283767652';
export const OVERSEA_TEXT = '+61 2 8376 7652';
export const SALES_HOURS = 'Mon – Fri, 9:00am – 7:00pm (AEST/AEDT)';
export const SUPPORT_HOURS = 'Mon - Sun, 8:30am - 5:30pm (AEST/AEDT)';

const ContactUs = ({ sentence, hours, ...props }) => {
  const contactUs = (
    <>
      <Link.Text
        fontWeight="bold"
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        href={`tel:${NUMBER}`}
      >
        {TEXT}
      </Link.Text>
      {', '}
      <span>{hours}</span>
    </>
  );

  if (sentence) {
    return <>Please call {contactUs}.</>;
  }

  return contactUs;
};

ContactUs.defaultProps = {
  sentence: false,
  hours: SALES_HOURS,
};

ContactUs.propTypes = {
  sentence: PropTypes.bool,
  hours: PropTypes.string,
};

export default ContactUs;
