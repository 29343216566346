import styled from '@emotion/styled';
import React from 'react';
import { Box, Button, Flex, Hide, Icon } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import ClickOutside from '@/components/ClickOutside';
import Dropdown from '@experiences-ui/shared/components/Dropdown';
import withFeatureFlag from '@/components/withFeatureFlag';
import withProps from '@/components/withProps/withProps';
import MemberDetails from './components/MemberDetails';
import MemberMenu from './components/MemberMenu';
import LoginSelection from '@/components/LoginSelection';
import ToggleContent from '@experiences-ui/shared/v2/components/ToggleContent';
import { useAuth } from '@/components/Auth';

const StyledButton = withProps({
  variant: 'primary',
  block: true,
  px: 2,
  py: 0,
})(styled(Button)`
  text-transform: none;
  height: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 1;

  @media (max-width: calc(${themeGet('breakpoints.1')} - 1px)) {
    border-radius: 0;
  }
`);

const HideMemberDetailsButton = styled(StyledButton)`
  border-radius: 0;
`;

const AuthButton = () => {
  const { memberDetails, logout } = useAuth();

  return (
    <Flex position="relative" zIndex="foreground" mb="-0.5rem" ml="5">
      {memberDetails ? (
        <Dropdown
          toggle={(show, ref, { isShown, hide }) => (
            <Box
              data-testid="AUTHENTICATION_DETAILS"
              height="100%"
              minWidth="300px"
              ref={ref}
            >
              {isShown ? (
                <HideMemberDetailsButton
                  data-testid="HIDE_MEMBER_DETAILS_DROPDOWN"
                  onClick={hide}
                >
                  <Flex alignItems="center">
                    <Icon name="close" />
                    <Hide xs sm>
                      &nbsp;CLOSE
                    </Hide>
                  </Flex>
                </HideMemberDetailsButton>
              ) : (
                <StyledButton
                  data-testid="SHOW_MEMBER_DETAILS_DROPDOWN"
                  onClick={show}
                >
                  <MemberDetails
                    title={memberDetails.title}
                    firstName={memberDetails.firstName}
                    lastName={memberDetails.lastName}
                    points={memberDetails.points}
                    membershipTier={memberDetails.membershipTier}
                  />
                </StyledButton>
              )}
            </Box>
          )}
          content={(hide) => (
            <ClickOutside onOutsideClick={hide}>
              <Box minWidth="300px">
                <MemberMenu
                  memberId={memberDetails.memberId}
                  firstName={memberDetails.firstName}
                  lastName={memberDetails.lastName}
                  points={memberDetails.points}
                  membershipTier={memberDetails.membershipTier}
                  onLogout={() => {
                    hide();
                    logout();
                  }}
                />
              </Box>
            </ClickOutside>
          )}
        />
      ) : (
        <ToggleContent
          toggle={(show) => (
            <StyledButton
              id="login_button"
              onClick={() => show()}
              data-testid="LOGIN"
            >
              LOGIN
            </StyledButton>
          )}
          content={(close) => <LoginSelection onClose={close} />}
        />
      )}
    </Flex>
  );
};

const EnhancedAuthButton = withFeatureFlag('QFF_AUTH')(AuthButton);

export default EnhancedAuthButton;
