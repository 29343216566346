import { createContext, useContext } from 'react';

const createCtx = <A extends {} | null>() => {
  const ctx = createContext<A | undefined>(undefined);

  const useCtx = () => {
    const c = useContext(ctx);

    // need to hide this whilst app/nav context still in legacy
    // if (c === undefined)
    //   throw new Error('useCtx must be inside a Provider with a value');

    return c;
  };

  return [useCtx, ctx.Provider] as const;
};

export default createCtx;
